import { getUA } from 'react-device-detect';

export const IMAGE_ROOT = `${process.env.REACT_APP_BASE_S3_IMAGE}/app`;

export const ICON_PATH = `${process.env.REACT_APP_BASE_S3_IMAGE}/app/icons`;

export const IS_MARTGO_APP = getUA.indexOf('mcoupon') > -1;

export const IS_DEV = process.env.REACT_APP_TYPE === 'DEV';
export const IS_STAGE = process.env.REACT_APP_TYPE === 'STAGE';
export const IS_PROD = process.env.REACT_APP_TYPE === 'PROD';

//local 테스트 용 기기정보 하드코딩
export const LOCAL_DEVICE_INFO = {
  device_id: '(null)',
  device_os: 'pc',
  model_nm: '',
  app_ver: '10.0.0',
  wdata: '',
  ukey: '',
  osVer: '15.0',
};

// 전단 URL 체크
export const LEAFLET_URL_CHECK = (url: string): boolean => {
  if (url.includes('mlotte.net')) {
    return true;
  } else {
    return false;
  }
};

//슈퍼 오늘뭐먹지? 하드코딩 이벤트 id
export const SUPER_EVENT_ID =
  process.env.REACT_APP_TYPE === 'DEV' ? '101' : '1171146';

//슈퍼 전단 하드코딩
export const SUPER_LEAFLET_URL = 'https://www.mlotte.net/leaflet?type=SUPER';

//슈퍼 하드코딩 체크
export const SUPER_CHECK = (str_cd: string): boolean => {
  if (str_cd === SUPER_INIT_DATA.str_cd) {
    return true;
  } else {
    return false;
  }
};

//슈퍼 하드코딩 점포정보
export const SUPER_INIT_DATA = {
  str_cd: 'ltsp',
  str_nm: 'SUPER',
  main_str_type: '4',
};

// 스캔앤고 L.PAY 노출 id 체크 하드코딩 (개발-소병욱과장)
export const LPAY_OPEN_ID =
  process.env.REACT_APP_TYPE === 'DEV' ? 'mcoupon72' : 'uddnr';
export const CHECK_LPAY_OPEN_ID = (userId: string): boolean => {
  if (userId === LPAY_OPEN_ID) {
    return true;
  } else {
    return false;
  }
};

// 스캔앤고 운영 무조건 실행 id 하드코딩 (요청-소병욱과장)
// kanghw95강현우 / uddnr소병욱 / da5870김다혜
export const SCANGO_OPEN_ID_LIST = ['kanghw95', 'uddnr', 'da5870@nate.com'];
export const CHECK_SCANGO_OPEN_ID = (userId: string): boolean => {
  if (SCANGO_OPEN_ID_LIST.indexOf(userId) > -1) {
    return true;
  } else {
    return false;
  }
};

// 스마트영수증 상세 점포명 표기 하드코딩-231228 김민수 대리님 요청
export const RECEIPT_TITLE_INFO = [
  {
    str_cd: '467',
    str_nm: '평촌점',
    str_title: '롯데쇼핑(주) 롯데식품관',
    hidden_logo: true,
  },
  {
    str_cd: '486',
    str_nm: 'TRU평촌점',
    str_title: '롯데쇼핑(주) 토이저러스',
    hidden_logo: true,
  },
];

// 241212 ~ 241225 스노우페스타 기간 메인 메뉴 아이콘 변경(슈퍼제외)
export const isSnowFestaEventDay = (
  currenTime: string,
  str_cd: string,
): boolean => {
  if (!SUPER_CHECK(str_cd) && currenTime) {
    const _currenTime = currenTime.slice(0, 12);
    const startDate =
      process.env.REACT_APP_TYPE === 'DEV' ? '202411250000' : '202412120000';
    return startDate <= _currenTime && _currenTime <= '202412251159'
      ? true
      : false;
  } else {
    return false;
  }
};

// 스캔앤고 버전체크 정보. AOS:4.0.7 / iOS:3.0.5
const scango_appVer_ios = '3.0.5';
const scango_appVer_aos = '4.0.7';
const scango_osVer_ios = '15.4';

export const SCANGO_UPDATE_VER_GUIDE = {
  scango_appVer_ios: scango_appVer_ios,
  scango_appVer_aos: scango_appVer_aos,
  scango_osVer_ios: scango_osVer_ios,
  scango_appVer_msg:
    'SCAN&GO를 이용하려면 앱 업데이트가 필요합니다. 업데이트를 하시겠습니까?',
  scango_osVer_msg:
    'iOS 15.4 버전 미만인경우 일부 바코드 스캔이 제한됩니다. iOS 버전을 최신버전으로 업데이트 후 이용해주세요.',
};
