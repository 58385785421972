import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import reportWebVitals from './reportWebVitals';
import configureStore from './store/configureStore';

import { ScrollToTop } from './utils/scroll';
import App from './containers/App';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './lang/i18n';
import { theme } from './styles/theme';
import GlobalStyles from './styles/GlobalStyles';

import isPropsVaild from '@emotion/is-prop-valid';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const store = configureStore();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // refetch 조건 막기
      retry: 0, // 요청 실패했을 경우 재요청 횟수
    },
  },
});

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={true} />
      {/* basename 필요 */}
      <BrowserRouter
        basename=""
        //React Router Future Flag Warning 경고창 안나오게 future 옵션 추가 -> 주석:뒤로가기 시 스크롤유지X, 화면전환시 깜빡임
        // future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
      >
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <ScrollToTop />
            <GlobalStyles />
            <StyleSheetManager shouldForwardProp={prop => isPropsVaild(prop)}>
              <App />
            </StyleSheetManager>
          </ThemeProvider>
        </HelmetProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
