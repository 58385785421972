enum ActionTypes {
  OPEN_CONFIRM_POPUP = 'app/OPEN_CONFIRM_POPUP',
  CLOSE_CONFIRM_POPUP = 'app/CLOSE_CONFIRM_POPUP',
  OPEN_APPCARD_POPUP = 'app/OPEN_APPCARD_POPUP',
  CLOSE_APPCARD_POPUP = 'app/CLOSE_APPCARD_POPUP',
  OPEN_ALERT_POPUP = 'app/OPEN_ALERT_POPUP',
  CLOSE_ALERT_POPUP = 'app/CLOSE_ALERT_POPUP',
  OPEN_TERMS_POPUP = 'app/OPEN_TERMS_POPUP',
  CLOSE_TERMS_POPUP = 'app/CLOSE_TERMS_POPUP',
  OPEN_SNOWPOINT_POPUP = 'app/OPEN_SNOWPOINT_POPUP',
  CLOSE_SNOWPOINT_POPUP = 'app/CLOSE_SNOWPOINT_POPUP',
  OPEN_MAIN_POPUP = 'app/OPEN_MAIN_POPUP',
  CLOSE_MAIN_POPUP = 'app/CLOSE_MAIN_POPUP',
  OPEN_SNOWPLAN_POPUP = 'app/OPEN_SNOWPLAN_POPUP',
  CLOSE_SNOWPLAN_POPUP = 'app/CLOSE_SNOWPLAN_POPUP',
  OPEN_WELCOME_POPUP = 'app/OPEN_WELCOME_POPUP',
  CLOSE_WELCOME_POPUP = 'app/CLOSE_WELCOME_POPUP',
  INIT_WELCOME_POPUP = 'app/INIT_WELCOME_POPUP',
  OPEN_LOADING_POPUP = 'app/OPEN_LOADING_POPUP',
  CLOSE_LOADING_POPUP = 'app/CLOSE_LOADING_POPUP',
  OPEN_INTRO_POPUP = 'app/OPEN_INTRO_POPUP',
  CLOSE_INTRO_POPUP = 'app/CLOSE_INTRO_POPUP',
  OPEN_START_SCANGO = 'app/OPEN_START_SCANGO',
  CLOSE_START_SCANGO = 'app/CLOSE_START_SCANGO',
  APP_DEVICE_INFO = 'app/APP_DEVICE_INFO',
  GET_MERGED_PUSH_LIST = 'app/GET_MERGED_PUSH_LIST',
  GET_SCANGO_BANNER = 'app/GET_SCANGO_BANNER',
  OPEN_SCANGO_INTRO_POPUP = 'app/OPEN_SCANGO_INTRO_POPUP',
  CLOSE_SCANGO_INTRO_POPUP = 'app/CLOSE_SCANGO_INTRO_POPUP',
  OPEN_MART_SETTING_POPUP = 'app/OPEN_MART_SETTING_POPUP',
  CLOSE_MART_SETTING_POPUP = 'app/CLOSE_MART_SETTING_POPUP',
  OPEN_INSPECTION_APPCARD_POPUP = 'app/OPEN_INSPECTION_APPCARD_POPUP',
  CLOSE_INSPECTION_APPCARD_POPUP = 'app/CLOSE_INSPECTION_APPCARD_POPUP',
  IS_HIDE_SPLASH = 'app/IS_HIDE_SPLASH',
  SET_SYSTEM_INSPECTION_POPUP_LIST = 'app/SET_SYSTEM_INSPECTION_POPUP_LIST',
  SET_SYSTEM_UPDATE_POPUP_LIST = 'app/SET_SYSTEM_UPDATE_POPUP_LIST',
  SET_MDPICK_YN = 'app/SET_MDPICK_YN',
}

export default ActionTypes;
