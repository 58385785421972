import React, { useEffect, useState } from 'react';
import Dim from '../Dim';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { E_POPUP_STATE } from 'src/utils/enum';
import CenteredPopupWrapper from './components/CenteredPopupWrapper';
import { StyledScangoIntroPopupContent } from './styles';
import { CheckBoxCheck } from '../CheckBox';
import { Image } from '../Image';
import { IMAGE_ROOT } from 'src/utils/constants';
import { Button } from '../Button';
import { useDispatch } from 'react-redux';
import {
  actionCloseScangoIntroPopup,
  actionOpenStartScango,
} from 'src/redux/actions/appActions';
import { gaEvent, gaPageView } from 'src/utils/ga';
import useSelectorEqual from 'src/hooks/useSelectorEqual';
import { RootState } from 'src/store/reducers';
import { isScangoAppUpdate } from 'src/utils/scango';

type TProps = {
  isOpen: E_POPUP_STATE;
};

SwiperCore.use([Pagination]);
function ScangoIntroPopup(props: TProps): React.ReactElement {
  const [isChecked, setIsChecked] = useState(false);

  const [deviceInfo] = useSelectorEqual((state: RootState) => [
    state.app.deviceInfo,
  ]);

  const dispatch = useDispatch();
  const pageName = '스캔앤고 튜토리얼 팝업';

  // 다시 보지 않기 체크
  const handleClickCheckArea = e => {
    e.preventDefault();
    gaEvent('다시 보지 않기', '', '', pageName);
    setIsChecked(!isChecked);
  };

  // SCAN&GO 시작하기 버튼 클릭
  const handleClickStartScango = () => {
    gaEvent('SCAN&GO 시작하기', '', '', pageName);

    // 다시 보지 않기 체크한 경우
    if (isChecked) {
      localStorage.setItem('scangoIntroPopup', 'N');
    }
    // 스캔앤고 인트로 팝업 닫기
    dispatch(actionCloseScangoIntroPopup());
    // 스캔앤고 시작 팝업 열기
    if (!isScangoAppUpdate(deviceInfo)) {
      dispatch(actionOpenStartScango());
    }
  };

  useEffect(() => {
    gaPageView(location.pathname, '', pageName);
  }, []);

  return (
    <>
      <Dim zIndex={202} isDisplay={props.isOpen === E_POPUP_STATE.OPEN} />
      <CenteredPopupWrapper padding="0" zIndex={203}>
        <StyledScangoIntroPopupContent>
          <div className="swiper_area">
            <Swiper
              slidesPerView="auto"
              loop
              pagination={{
                type: 'bullets',
                clickable: true,
              }}
            >
              {SCANGO_INTRO_LIST.map((popup, i) => {
                return (
                  <SwiperSlide key={i}>
                    <Image
                      src={`${IMAGE_ROOT}/default/${popup.img}.jpg`}
                      alt=""
                      borderRadius="0"
                    />
                    <div
                      className="blind"
                      dangerouslySetInnerHTML={{ __html: popup.desc }}
                    ></div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="btn_area">
            <div className="check_area" onClick={handleClickCheckArea}>
              <CheckBoxCheck
                id="mainPopupClose"
                isChecked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              />
              <p>다시 보지 않기</p>
            </div>
            <Button
              text="SCAN&GO 시작하기"
              onClick={handleClickStartScango}
              variant="contained"
              buttonColor="red"
            />
          </div>
        </StyledScangoIntroPopupContent>
      </CenteredPopupWrapper>
    </>
  );
}

export default ScangoIntroPopup;

export const SCANGO_INTRO_LIST = [
  {
    img: 'scango_intro01',
    desc: `SCAN&GO가 무엇인가요?<br/>SCAN&GO는 롯데마트GO앱을 통해 상품의 바코드를 스캔하고 모바일로 결제하는 비대면 결제 서비스예요.`,
  },
  {
    img: 'scango_intro02',
    desc: `01 점포설정<br/>방문 점포와 설정 점포가 동일한지 확인해주세요.`,
  },
  {
    img: 'scango_intro03',
    desc: `02 상품스캔<br/>상품의 바코드를 스캔해주세요.`,
  },
  {
    img: 'scango_intro04',
    desc: `03 카드결제<br/>장바구니 목록을 확인하고 결제를 진행해주세요.`,
  },
  {
    img: 'scango_intro05',
    desc: `04 게이트 퇴장<br/>결제 완료 QR코드를 게이트 태블릿에 스캔하고 퇴장해주세요.`,
  },
];
